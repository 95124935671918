import getConfig from "next/config";
import {
  ArticleResponse,
  CategoryResponse,
  GuideCategoryResponse,
  GuideResponse,
  TagResponse,
  TermResponse,
} from "./apiTypes";
import Strapi, { StrapiRequestParams } from "strapi-sdk-js";
const { publicRuntimeConfig } = getConfig();
/**
 * Get full CMS URL from path
 * @param {string} path Path of the URL
 * @returns {string} Full CMS URL
 */
export function getCMSURL(path = "") {
  return `${publicRuntimeConfig.NEXT_PUBLIC_CMS_API_URL}${path}`;
}

const strapi = () => {
  return new Strapi({
    prefix: "/api",
    url: getCMSURL(),
  });
};

export const getArticles = (params: StrapiRequestParams) => {
  return strapi()
    .find("articles", params)
    .then((res) => res as ArticleResponse);
};

export const getCategories = () => {
  return strapi()
    .find("categories")
    .then((res) => res as CategoryResponse);
};

export const getGuideCategories = () => {
  return strapi()
    .find("guide-categories")
    .then((res) => res as GuideCategoryResponse);
};

export const getGuides = (params: StrapiRequestParams) => {
  return strapi()
    .find("guides", params)
    .then((res) => res as GuideResponse);
};

export const getTags = () => {
  return strapi()
    .find("tags")
    .then((res) => res as TagResponse);
};

export const getTerms = async (slug: string) => {
  return strapi()
    .find("terms", { filters: { slug: slug } })
    .then((res) => res as TermResponse);
};
